body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #08468f;
  background-image: url("./images/background-shapes.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

h2 {
  color: #079165;
  font-size: 30px;
}

.header-nav > ul {
  list-style-type: none;
  padding-left: 0;
}

.header-nav li {
  display: inline-block;
  margin: 2px;
  padding: 5px 10px;
  border: 2px solid #000;
  background: #f6ea3c;
}

.slanted-box {
  transform: skewX(-10deg);
  background: #f6ea3c;
  color: #d4001f;
  padding: 5px 10px;
  border: 2px solid #000;
  display: inline-block;
}

.quiz {
  color: #000;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 600;
}

.quiz-reset {
  margin-left: 10px;
  margin-right: 10px;
}

.quiz-submit {
  padding: 5px;
  background: #f6ea3c;
  color: #08468f;
  border: 2px solid #000;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
}

.quiz-submit:hover {
  background: #eee;
}

.cocktail-name {
  border: 4px solid #fbf7f4;
  text-transform: uppercase;
  padding: 5px;
}

.cocktail-image {
  width: 100%;
  padding-top: 20px;
}

.quiz header {
  font-size: 35px;
  font-weight: 800;
  color: #000;
}

.inc-list-container {
  overflow: scroll;
}

.inc-title {
  font-size: 1.5rem;
  color: #000;
  text-decoration: underline;
  margin-bottom: 0;
}

.inc-recipe {
  font-size: 1rem;
  margin-top: 0;
  letter-spacing: 1px;
}

.inc-cocktail-name {
  margin-bottom: 5px;
}

.ingredients-title {
  font-size: 25px;
  height: 18%;
  border-bottom: 1px solid #000;
  text-align: center;
  line-height: 80px;
  margin: 0;
  color: #000;
}

.ingredients-container {
  position: relative;
  max-height: 1000px;
  height: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  background: white;
  padding-bottom: 1px;
}

.ingredients-list-container {
  height: 82%;
  overflow: scroll;
}

.ingredients-container button {
  position: absolute;
  width: 100%;
  height: 18%;
  left: 0;
  bottom: 0;
  border-radius: 0;
  border: 1px solid;
  border-left: 0;
  border-right: 0;
  font-size: 30px;
}

.ingredient-inputs {
  width: 100%;
  height: 20%;
  margin: 0px 0px 1px 0px;
  border: solid #000;
  border-width: 0px 0px 1px 0px;
  padding-bottom: 1px;
}

.quiz-boxes-container {
  display: flex;
  align-items: flex-start;
  padding: 0 5% 0 5%;
  height: 400px;
}
.quiz-boxes {
  width: 400px;
  display: inline-block;
  height: 100%;
  border: 1px solid #000;
  background: #fff;
}

.quiz-form {
  height: 100%;
}

.ingredient-inputs > * {
  height: 100%;
  font-size: 17px;
  padding: 0;
  margin: 0;
  border: none;
}

.quantity-input {
  width: 22%;
  padding-left: 1%;
}

.measurement-input {
  width: 20%;
  background: #fff;
  /* background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none; */
}

.ingredient-name-input {
  width: 55%;
  padding-left: 2%;
}

.cocktail-title {
  font-size: 25px;
  height: 18%;
  border-bottom: 1px solid #000;
  text-align: center;
  line-height: 80px;
  margin: 0;
  color: #000;
}

.quiz-boxes.left {
  margin-left: auto;
  border-right: solid 1px #000;
}

.quiz-boxes.right {
  margin-right: auto;
  border-left: 0px;
}

.cocktail-name-input {
  width: 100%;
  max-width: 500px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #000;
  margin-bottom: 15px;
  font-size: 20px;
}

.social-links {
  display: inline-block;
  padding: 10px px 20px;
  background: #eee;
  border: 2px solid #000;
  margin-bottom: 20px;
}

.add-form-container {
  height: 450px;
  padding: 0px 5px 0px 5px;
}

.add-form-container .ingredients-container {
  border: 1px solid #000;
  margin-bottom: 15px;
}

.header-nav .button {
  background: #079165 !important;
  padding: 10px;
  min-width: 100px;
}

.header-nav a {
  color: #08468f;
  font-weight: 600;
  text-decoration: none;
}

.loader,
.loader > * {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loader {
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  background: #08468f;
}
.loader > * {
  transform: translate(-10%, -15%);
}

.add-cocktail-message {
  background: #fff;
  padding: 5px 15px;
}

.amp-sign-out-button {
  width: 153px;
  margin: auto;
}

.selfie {
  border: solid 2px #000;
  max-width: 300px;
}

.bio-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
}

.bio-row-item {
  margin: 15px;
  display: flex;
  align-items: center;
}

.bio-text {
  padding: 20px;
  background: #1f538f;
  border: 2px solid #000;
  color: #ccc;
  max-width: 300px;
  border-radius: 2px;
}

.linkedIn-logo {
  max-width: 50px;
  margin: 20px;
}

.github-logo {
  max-width: 90px;
  margin: 20px 20px 20px 0;
}

@media only screen and (max-width: 900px) {
  .quiz-boxes-container {
    flex-direction: column;
    align-items: center;
    height: 800px;
  }

  .quiz-boxes.left {
    border: 1px solid #000;
    border-bottom: solid 1px #000;
    margin: 0;
  }

  .quiz-boxes.right {
    border: 1px solid #079165;
    border-top: 0px;
    margin: 0;
  }
}

@media only screen and (max-width: 420px) {
  .quiz-boxes {
    width: 350px;
  }
}

:root {
  --amplify-primary-color: #000;
}
